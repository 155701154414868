import React from "react";
import MyAccountLayout from "../../components/myAccountLayout";

const LoginWelcome = () => <MyAccountLayout>
  <div className='font-sans font-light text-2xl text-center mt-16 mb-32 my-2'>Welcome to your personal space. You can
    always
    come back
    here by clicking on your name in the menu.
  </div>
</MyAccountLayout>

export default LoginWelcome